import { ComponentProps, ElementType, Ref, forwardRef, ReactElement } from 'react';
import isPropValid from '@emotion/is-prop-valid';

/**
 * Polymorphic base component that checks valid prop types and filters out React props
 * Based on: https://github.com/kripod/react-polymorphic-box/blob/main/src/Box.tsx
 *
 */
export type ViewOwnProps<E extends ElementType = ElementType> = {
    as?: E;
};

export type BoxProps<E extends ElementType> = ViewOwnProps<E> & Omit<ComponentProps<E>, keyof ViewOwnProps>;

const defaultElement = 'div';

export const View: <E extends ElementType = typeof defaultElement>(
    props: BoxProps<E>
) => ReactElement | null = forwardRef((props: ViewOwnProps, ref: Ref<Element>) => {
    const Element = props.as || defaultElement;
    let finalProps = props;

    // If element is a HTML DOM element, ie. "string" instead of {} (React component),
    // we check its props and filter out anything that isn't native
    if (typeof Element === 'string') {
        finalProps = {};

        Object.entries(props).forEach(([key, value]) => {
            if (isPropValid(key)) {
                finalProps[key] = value;
            }
        });
    }
    return <Element as={undefined} ref={ref} {...finalProps} />;
});

export default View;
