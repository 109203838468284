import { ReactNode } from 'react';
import { tw } from 'twind';
import { View } from 'components/01_tw/View';

interface ContentProps {
    align?: 'left' | 'right' | 'center';
    as: 'h1' | 'h2' | 'h3' | 'h4';
    children: ReactNode;
    className?: string;
    color?: 'black' | 'white' | 'dark' | 'medium' | 'light' | 'lightest' | 'emerald';
    font?: 'sans' | 'serif';
    gutterBottom?: boolean;
    modalHeader?: boolean;
    styleAs?: 'h1' | 'h2' | 'h3' | 'h4';
    textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
    weight?: 'medium' | 'semibold' | 'bold';
}

const Heading = ({
    align,
    as,
    children,
    className,
    color = 'black',
    font = 'serif',
    gutterBottom,
    modalHeader,
    styleAs,
    textTransform,
    weight
}: ContentProps) => {
    const isOrStyledAsH1 = (as === 'h1' && !styleAs && !modalHeader) || styleAs === 'h1';
    const isOrStyledAsH2 = (as === 'h2' && !styleAs && !modalHeader) || styleAs === 'h2';
    const isOrStyledAsH3 = (as === 'h3' && !styleAs && !modalHeader) || styleAs === 'h3';
    const isOrStyledAsH4 = (as === 'h4' && !styleAs && !modalHeader) || styleAs === 'h4';

    return (
        <View
            as={as}
            className={tw(
                `leading-normal font-${font}`,
                textTransform,
                {
                    'text-white': color === 'white',
                    'text-gray-900': color === 'black',
                    'text-gray-700': color === 'dark',
                    'text-gray-600': color === 'medium',
                    'text-gray-500': color === 'light',
                    'text-gray-400': color === 'lightest',
                    'text-emerald-500': color === 'emerald'
                },
                {
                    'text-4xl leading-snug md:(text-5xl leading-snug)': isOrStyledAsH1,
                    'text-3xl': isOrStyledAsH2,
                    'text-xl': isOrStyledAsH3,
                    'text-base': isOrStyledAsH4,
                    'text-2xl mb-3': modalHeader
                },
                {
                    'mb-6': isOrStyledAsH1 && gutterBottom,
                    'mb-4': isOrStyledAsH2 && gutterBottom,
                    'mb-3': isOrStyledAsH3 && gutterBottom,
                    'mb-1': isOrStyledAsH4 && gutterBottom
                },
                {
                    [`text-${align}`]: Boolean(align),
                    [`font-${weight}`]: Boolean(weight)
                },
                {
                    'normal-case': textTransform === 'none'
                },
                className
            )}
        >
            {children}
        </View>
    );
};

export default Heading;
