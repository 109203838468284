import NextHead from 'next/head';
import startsWith from 'lodash/startsWith';
import config from 'lib/config';

interface HeadProps {
    title?: string;
    favicon?: string;
    description?: string;
    image?: string;
    addTrello?: boolean;
    children?: HTMLElement;
}

export default function Head({ title, favicon, description, image, addTrello, children }: HeadProps) {
    const openGraphImage = image || '/static/images/pastel-open-graph.png';
    const prefixedOpenGraphImage = startsWith(openGraphImage, '/')
        ? `https://${config.host}${openGraphImage}`
        : openGraphImage;

    return (
        <NextHead>
            <title>{title || 'Pastel | Faster feedback and approvals on your creative work'}</title>
            <link rel="shortcut icon" href={favicon || '/static/images/favicon.png'} />
            <meta
                name="description"
                content={
                    description ||
                    'Pastel is the easiest and fastest way for web designers, developers and marketing teams to get feedback and approvals on websites and other creative work.'
                }
            />
            <meta property="og:image" content={prefixedOpenGraphImage} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:image" content={prefixedOpenGraphImage} />
            <meta name="twitter:card" content="summary_large_image" />
            {config.analytics.enabled && (
                <script
                    type="text/javascript"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: `
                    (function() {
                        var w = window;
                        var ic = w.Intercom;
                        if (typeof ic === "function") {
                            ic('reattach_activator');
                            ic('update', intercomSettings);
                        } else {
                            var d = document;
                            var i = function() {
                                i.c(arguments)
                            };
                            i.q = [];
                            i.c = function(args) {
                                i.q.push(args)
                            };
                            w.Intercom = i;

                            function l() {
                                var s = d.createElement('script');
                                s.type = 'text/javascript';
                                s.async = true;
                                s.src = 'https://widget.intercom.io/widget/zxaivxcc';
                                var x = d.getElementsByTagName('script')[0];
                                x.parentNode.insertBefore(s, x);
                            }
                            if (w.attachEvent) {
                                w.attachEvent('onload', l);
                            } else {
                                w.addEventListener('load', l, false);
                            }
                        }
                    })()`
                    }}
                />
            )}
            {config.analytics.enabled && (
                <>
                    <script
                        type="text/javascript"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: `
                    var _cio = _cio || [];
                    (function() {
                        var a,b,c;a=function(f){return function(){_cio.push([f].
                        concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
                        "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
                    })();`
                        }}
                    />
                    <script
                        type="text/javascript"
                        async
                        id="cio-tracker"
                        data-site-id={config.customerIO.siteId}
                        data-use-array-params="true"
                        data-in-app-org-id={config.customerIO.organizationId}
                        data-use-in-app="true"
                        src="https://assets.customer.io/assets/track.js"
                    />
                </>
            )}
            {addTrello && (
                <>
                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" />
                    <script src={`https://trello.com/1/client.js?key=${config.trello.publicKey}`} />
                </>
            )}
            <script
                type="text/javascript"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: `
                (function() {var gs = document.createElement('script');gs.src = 'https://snippet.growsumo.com/growsumo.min.js';gs.type = 'text/javascript';gs.async = 'true';gs.onload = gs.onreadystatechange = function() {var rs = this.readyState;if (rs && rs != 'complete' && rs != 'loaded') return;try {growsumo._initialize('pk_2Z5QIv5t4O6gBNEj6HgdRnCaoIX8A89j');if (typeof(growsumoInit) === 'function') {growsumoInit();}} catch (e) {}};var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(gs, s);})();
            `
                }}
            />
            {children}
        </NextHead>
    );
}
